@import "../variables";

body {
    background-color: $page-auth-bg;
}
#auth {
    height: 100vh;
    overflow-x: hidden;

    #auth-left {
        height: 100%;
        background:  url(../images/left-green.png), $page-auth-right-bg;
        background-repeat: no-repeat;
        background-size: cover;

        .container{
            position: relative;
            height: 100%;

            .brand {
                margin-left: 3rem;

                p {
                    font-weight: 600;
                    color: #fff;
                }
            }
            .content {
                position: absolute;
                top: 5%;
                left: 0;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                p{
                    font-size: 3.5rem;
                    text-align: left;
                    color: #fff;
                    margin-left: 1.5rem;
                }
            }
        }
    }
    #auth-right {

        .auth-title {
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        .auth-subtitle {
            font-size: 1.25rem;
            line-height: 2.5rem;
            color: #a8aebb;
        }
        .auth-logo {
            margin-bottom: 7rem;
            img {
                height: 2rem;
            }
        }
        @media screen and (max-width: 767px) {
            padding: 5rem ;
        }
        @media screen and (max-width: 576px) {
            padding: 5rem 3rem;
        }
    }
}

body.theme-dark {
    @import "../themes/dark/variables-dark";
}
